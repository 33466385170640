import {
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  DialogContentText
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { Button, LeftSideBar } from "../components";
import { useState, useMemo, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import launch from "../assets/creatorOutreachAvatar.PNG";
import brand_clip from "../assets/brands_clip.PNG";
import brand_insights from "../assets/brand insights.PNG";
import TrendingHashtags from "../assets/TrendingHashtags.PNG";
import Olympics from "../assets/Olympics.PNG";
import familyTravel from "../assets/familyTravel.PNG";
import MoreCreators from "../assets/moreCreators.PNG";



import { parseFollowers, fetchGet, formatStat } from "../util/helpers";
import { InstagramEmbed } from "react-social-media-embed";



const Landscape = () => {
  const [rightGridHeight, setRightGridHeight] = useState(0);
  const rightGridRef = useRef(null);
  const navigate = useNavigate();
  const [nameSearch, setNameSearch] = useState("");
  const [landScapeData, setLanScapeData] = useState([]);
  const [externalDialogOpen, setExternalDialogOpen] = useState(false)
  const [externalCreator, setExternalCreator] = useState(null)
  const [externalLoading, setExternalLoading] = useState(false)
  const followerComparator = (a, b) => {
    return parseFollowers(a) - parseFollowers(b);

  };
  const columns = useMemo(
    () => [
      { field: "company", headerName: "Company", flex: 1 },
      { field: "industry", headerName: "Industry", flex: 2 },
      {
        field: "instagram_followers",
        headerName: "Instagram",
        flex: 0.9,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      {
        field: "youtube_followers",
        headerName: "YouTube",
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      {
        field: "tiktok_followers",
        headerName: "Tiktok",
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      {
        field: "linkedin_followers",
        headerName: "LinkedIn",
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      {
        field: "twitter_followers",
        headerName: "Twitter",
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Visit Primary Channel",
        sortable: false,
        flex: 2,
        getActions: (params) => [
          <IconButton
            sx={{
              width: 50, height: 50, backgroundColor: "#4158D0",
              backgroundImage: "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
            }}
            onClick={() => {
              if (params.row.id === "custom-row") {
                setExternalDialogOpen(true)
              }else {
                window.open(params.row.primary_channel, "blank")
              }
            }}
          >
            <OpenInNew />
          </IconButton>
          // <Button
          //   style={{
          //     backgroundColor: "#4158D0",
          //     backgroundImage:"linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
          //     borderRadius: 20,
          //     fontSize: 14,
          //   }}
          //   // href={params.row.primary_channel}
          //   // target="blank"
          // >
          //   Visit
          // </Button>,
        ],
      },
    ],
    [],
  );
  const fetchLandScape = async () => {
    fetchGet(`/brand/landscape`)
      .then((result) => result.result)
      .then((result) => {
        setLanScapeData(result.map((res) => ({ ...res, id: res.company })));
      });
  }
  useEffect(() => {
    fetchLandScape();
  }, []);

  useEffect(() => {
    console.log("nameSearch", nameSearch, externalDialogOpen)
    if (externalDialogOpen && nameSearch) {
      setExternalLoading(true)
      fetchGet(`/brand/external/outreach?username=${nameSearch}`)
      .then((res) => {
        console.log("response", res)
        setExternalCreator(res)
      })
      .finally(() => setExternalLoading(false))
    }
  }, [externalDialogOpen, nameSearch])

  useEffect(() => {
    if (rightGridRef.current) {
      const updateHeight = () => {
        setRightGridHeight(rightGridRef.current.clientHeight);
      };

      updateHeight();

      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [rightGridRef.current]);

  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.company.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.primary_channel.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };
    const filtered =  landScapeData.filter((rowData) => {
      return checkName(rowData);
    })
    if (nameSearch && filtered.length < 10) {
      filtered.push({
        id: 'custom-row',
        company: "",
        followers: "",
        industry: "Can't find the brand?",
        instagram_followers: "",
        youtube_followers: "",
        tiktok_followers: "",
        linkedin_followers: "",
        twitter_followers: "",
        actions: ""
    })
    }
    return filtered
  }, [landScapeData, nameSearch])


  return <>
  <Dialog fullWidth maxWidth="lg" open={externalDialogOpen} onClose={() => {setExternalDialogOpen(false); setExternalCreator(null)}}>
  {externalLoading && <DialogTitle>Fetching user data!</DialogTitle> }
 {!externalLoading && externalCreator === null && <DialogContent>
    <DialogTitle>Not Found</DialogTitle>
The user {nameSearch} could not be found!
  </DialogContent>}
  {externalCreator !== null && <DialogContent>
    <DialogTitle>{nameSearch} <Avatar
          fetchPriority="high"
          src={launch}
          style={{ width: 80, height: 80, marginRight: 5, float: "right" }}
        /></DialogTitle>
        <Box sx={{width: "95%", margin: "0 auto"}}>
<DialogContentText>{externalCreator.full_name}</DialogContentText>
<DialogContentText>{externalCreator.biography}</DialogContentText>
<DialogContentText>{formatStat(externalCreator.follower_count)} Followers</DialogContentText>
<DialogContentText>{formatStat(externalCreator.media_count)} Posts</DialogContentText>
<Grid container>

{externalCreator?.medias?.slice(0, 3).map(media => (<Grid item xs={4}> <InstagramEmbed width="100%" key={media.code} url={`https://www.instagram.com/p/${media.code}/`} /></Grid>))}
</Grid>
</Box>

  </DialogContent>}
</Dialog>
    <Grid container>
      <div style={{ width: "90vw" }}>
        <Grid container>
          <Grid item xs={2} style={{ textAlign: "left", height: rightGridHeight }}>
            <LeftSideBar />
          </Grid>
          <Grid item xs={10} style={{ textAlign: "left" }} ref={rightGridRef}>
            <Typography
              style={{ fontSize: 30, fontWeight: 600 }}
            >
              Competitive Landscape: Check Out Trending Brands
            </Typography>
            <p
              style={{
                fontSize: 20,
                marginTop: 10,
              }}
            >
              With Gigl's Competitive Analysis, you can see how other brands are performing across social channels. Check out some of the top trending brands across industries.
            </p>
            <img
              alt="brand_clip"
              fetchpriority="high"
              src={brand_clip}
              style={{
                maxWidth: "100%",
              }}
            ></img>
            <Typography
              variant="h5"
              component="div"
              style={{ textAlign: "left", marginBottom: 20, marginTop: 40 }}
            >
              Filter Below
            </Typography>
            <TextField
              label="Name"
              sx={{ height: 25, marginRight: 1, minWidth: "100%", marginBottom: 8 }}
              defaultValue={nameSearch}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
              InputProps={{
                style: {
                  borderRadius: "50px",
                },
              }}
            />
            <div style={{ height: 500, marginBottom: 50 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[20, 50, 100]}
              />
            </div>

            <Typography
              style={{ fontSize: 25, fontWeight: 600 }}
            >
              Brand Insights
            </Typography>
            <p
              style={{
                fontSize: 15,
                marginTop: 10,
              }}
            >
              Keep track of the latest trends on social.
            </p>
            <img
              alt="brand_clip"
              fetchpriority="high"
              src={brand_insights}
              style={{
                maxWidth: "100%",
              }}
            ></img>

            <Typography
              style={{ fontSize: 20, fontWeight: 600 }}
            >
              Relevant and Top Trending Hashtags this Month
            </Typography>
            <p
              style={{
                fontSize: 15,
                marginTop: 10,
              }}
            >
              Use some of these trending hashtag suggestions in your next post.
            </p>
            <img
              alt="brand_clip"
              fetchpriority="high"
              src={TrendingHashtags}
              style={{
                maxWidth: "100%",
              }}
            ></img>

            
            <Typography
              style={{ fontSize: 20, fontWeight: 600 }}
            >
              What's Trending on Social?
            </Typography>
            <p
              style={{
                fontSize: 15,
                marginTop: 10,
              }}
            >
              Check out some of the trending topics and content this month
            </p>



            <Grid container>
                            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 5, }}>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h8"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        1. 2024 Paris Olympics
                                        <br />
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                    >
                                  The Olympics is a top trending topic this month. Content featuring Olympics reviews, athletics, sports, and athlete/workout routines is also a trending topic. Sub-topics that are trending around the Olympics are: The Comeback of Simone Biles and Celine Dion, The Opening Ceremony, Paris as a travel destination is also trending.
                                  </Typography>

                                </div>
                                <div style={{marginBottom: 8}}>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "bold", marginBottom: 10 }}
                                    gutterBottom
                                >
                                    Check out some examples: 
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C-LgWkFvupc/?igsh=bXdtbXowanNtYWFt'> here </a> and
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C-UD_dWtEH3/?igsh=MTZoNnA3b3BmMDY1ZA=='> here </a>

                                </Typography>

                                </div>

                                <div style={{marginBottom: 8, marginTop: 30}}>
                                    <Typography
                                        variant="h8"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        2. Paging Dr. Beat
                                        <br />
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                    >
                                      Content using the Paging Dr. Beat format has been going viral, with videos using this trending format gaining higher viewership and engagement. These videos focus on inspirational content, like outfit inspiration, recipe inspiration, business growth tips, and travel inspiration. From a business lens, we have seen companies showcasing their favorite products, or comedic/educational content around their business and team.                                     
                                      </Typography>

                                </div>

                                <div style={{marginBottom: 8}}>

                                  <Typography
                                      variant="h7"
                                      style={{ fontWeight: "bold", marginBottom: 10 }}
                                      gutterBottom
                                  >
                                      Check out some examples: 
                                      <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9x6vn2PZ0X/?igsh=cm1yZnlzN25kaXFh'> here </a> and
                                      <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9tA-BgOWGr/?igsh=ODR1Yng0bWt0ejg3'> here </a>
                                  </Typography>

                                  </div>
                                

                                  <div style={{marginBottom: 8, marginTop: 30}}>
                                    <Typography
                                        variant="h8"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        3. Politics
                                        <br />
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                    >
                                      Politics is a trending topic of discussion across social channels. Discussion on the political landscape has exponentially grown in engagement and viewership.
                                    </Typography>

                                </div>
                                <div style={{marginBottom: 8}}>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "bold", marginBottom: 10 }}
                                    gutterBottom
                                >
                                    Check out some examples: 
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/explore/tags/politics/?hl=en'> here </a> 
                                </Typography>

                                </div>

                            </Grid>
                            <Grid item xs={6}  sx={{ marginTop: 1 }}> 
                                <img
                                    alt=""
                                    fetchpriority="high"
                                    src={Olympics}
                                    style={{
                                        maxWidth: "80%",
                                        maxHeight: "80%",
                                        marginLeft: 130
                                    }}
                                ></img>
                            </Grid>
                            </Grid>

                         


                            <Grid container>
                            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 8, }}>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h8"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        4. Back to School
                                        <br />
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                    >
Back to school is one of the top  topics trending on social, with students and parents sharing their back-to-school essentials, outfits, meal prep, and more. Comedic posts surrounding back-to-school have been trending at this time, as well as teacher-focused content, and parent’s tips/views on back-to-school for their kids.                                    
                                    </Typography>

                                </div>
                                <div style={{marginBottom: 8}}>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "bold", marginBottom: 5 }}
                                    gutterBottom
                                >
                                    Check out some examples: 
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9qeXJFNHuE/?igsh=am9iaGZwZ20xNngz'> here </a> and
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9hw0NbJa6j/?igsh=MXg2aXlxdHZqbWVvbw=='> here </a>
                                </Typography>

                                </div>

                                <div style={{marginBottom: 8, marginTop: 30}}>
                                    <Typography
                                        variant="h8"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        5. Family Fun & Travel
                                        <br />
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                    >
Family and nostalgia centered content is trending. Video content surrounding family travel ideas, family-focused comedy, family recipe favorites, family dynamics etc. is gaining higher veiwership and engagement.                          
                                    </Typography>

                                </div>
                                <div style={{marginBottom: 8}}>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "bold", marginBottom: 5 }}
                                    gutterBottom
                                >
                                    Check out some examples: 
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9gFKglSvkk/?igsh=MXQxbHVrNTc0c3F0dQ=='> here </a> and
                                    <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9QqImcSB9x/?igsh=azRocWJpaW1jaW5r'> here </a>     
                                    <br/>
                                    <br/>                           
                                    </Typography>



                                </div>

                            </Grid>
                            <Grid item xs={6}  sx={{ marginTop: 1 }}> 
                                <img
                                    alt=""
                                    fetchpriority="high"
                                    src={familyTravel}
                                    style={{
                                        maxWidth: "85%",
                                        maxHeight: "85%",
                                        marginLeft: 130
                                    }}
                                ></img>
                            </Grid>
                            </Grid>


            
                            <Typography
                              style={{ fontSize: 20, fontWeight: 600 }}
                            >
                              Trending Sounds & Audio 
                            </Typography>
                            <p
                              style={{
                                fontSize: 15,
                                marginTop: 10,
                              }}
                            >
                              Check out some of the trending sounds and audio this month. Use these sounds to increase relevance and boost performance of your content.
                            </p>
                                            
                            <Grid container>
                            <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", gap: "1em", }}>
                                <div >

                                <ul>
                                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/921447351682109?igsh=MTVodzdvbHNucjBmeQ==">August - Taylor Swift</a></li>
                                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/475442598416077?igsh=MWRweTgyNTh1d2tlMg==">Big Dawgs - Hunumankind</a></li>
                                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/252168608699319/">Bye Bye Bye - NSYNC</a></li>
                                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1235408740804610/">Paging Dr. Beat - Gloria Estefan</a></li>
                                      <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1662934874536027/">Juna - Clairo</a></li>


                                </ul>

                                </div>
                            </Grid>
                            <Grid item xs={4}> 
                            <div >

                                  <ul>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1014523328754040?igsh=dWF6OTh2cDR0dGFm"> Kickstart My Heart - Motley Creu </a></li>
                                        <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/268777446194642?igsh=MTY4dnE3bjB5enAzNQ==">Apple - Charli_xcx</a></li>
                                        <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1235451451148870?igsh=dWswbjB3OHNpMXBp">Guess - Charli_xcx</a></li>
                                        <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/461115589969942?igsh=MXJ3ZmQ0azAwNXB0Ng==">Soh-Soh - Iamodeal</a></li>

                                        
                                  </ul>

                              </div>
                            </Grid>

                            <Grid item xs={4}  > 
                            <div >

                                  <ul>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/592467431123776?igsh=aWlxMzJ0bndlcGN5">Sparks - Coldplay</a></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1727097854417516?igsh=cTU4OHMzbzluazV0">You - Tash</a></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/998641734507477?igsh=eG04Y2RpMjFybjIy">Hours - Hotel Pools</a></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/760459706041276?igsh=ZDloMWw2dGplbjFn">Too Sweet - The Macrons Project</a></li>

                                  </ul>

                              </div>
                            </Grid>
                            </Grid>

                            <img
                            alt=""
                            fetchpriority="high"
                            src={MoreCreators}
                            style={{
                              maxWidth: "95%",
                              marginTop: 50
                            }}
                          ></img>


                            <Grid container>
                            <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", gap: "1em", }}>
                                <div >

                                <Typography
                            variant="h6"
                            component="div"
                            style={{ textAlign: "left", marginTop: 30, marginBottom: 10, fontWeight: 600 }}
                          >
                            Looking for Creator Insights? 
                            <br />
                            Visit Your Discovery Page.
                          </Typography>

                                </div>

                            </Grid>
                            <Grid item xs={6} sx={{marginTop: 1}}> 
                            <div >


                                  <Button
                                  basic
                                  style={{
                                    marginTop: 25,
                                    borderRadius: 3,
                                    width: "100%",
                                    height: 60,
                                    marginBottom: 30,
                                    marginLeft: 50,
                                    backgroundColor: "#4158D0",
                                    backgroundImage:
                                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                                    fontSize: 12,
                                  }}
                                  onClick={() => navigate("/campaigns/creatordiscovery")}
                                >
                                  Visit Creator Discovery
                                </Button>

                              </div>
                            </Grid>
                            </Grid>
                                      



          </Grid>
        </Grid>
      </div>
    </Grid>
  </>
}

export default Landscape;